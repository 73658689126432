// menuItems.js
export const getMenuItems = (navigate) => [
  //   Academic Life Section
  {
    label: "Academic Life",
    icon: "",
  },
  // Curriculum Menu Items
  {
    label: "Curriculum",
    icon: "",
    items: [
      {
        label: "Curriculum",
        icon: "",
        command: () => {
          navigate("/curriculum");
        },
        items: [
          {
            label: "Curriculum",
            command: () => {
              navigate("/curriculum");
            },
          },
        ],
      },
      {
        label: "Courses",
        icon: "",
        items: [
          {
            label: "Project",
            command: () => {
              navigate("/projects");
            },
          },
          {
            label: "Student project",
            command: () => {
              navigate("/studentProjects");
            },
          },
          { label: "Sitare github" },
          { label: "Sitare hugging face" },
          { label: "Faculty projects" },
        ],
      },
      {
        label: "Admissions",
        icon: "",
        items: [
          { label: "How to apply" },
          { label: "Requirements" },
          { label: "Placements" },
          { label: "Required Document" },
          { label: "Contact Us" },
          { label: "Requirements" },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   University Menu Items
  {
    label: "University life",
    icon: "",
    items: [
      { label: "University life" },
      {
        label: "Academic Life",
        icon: "",
        items: [
          { label: "Career Services" },
          { label: "Library" },
          { label: "Campus Map" },
          { label: "Academic Calendar" },
          { label: "Student Support" },
        ],
      },
      {
        label: "Student Life",
        icon: "",
        items: [
          { label: "Sports" },
          { label: "Clubs" },
          { label: "Events" },
          { label: "Fests" },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   About Us Menu Item
  {
    label: "About Us",
    icon: "",
    items: [
      // { label: "About Us", icon: "" },
      {
        label: "About Us",
        icon: "",
        command: () => {
          navigate("/about");
        },
        items: [
          {
            label: "About Us",
            command: () => {
              navigate("/about");
            },
          },
        ],
      },
      [
        {
          label: "Who We Are",
          icon: "",
          items: [
            { label: "Our Past" },
            { label: "Our Present" },
            { label: "Our Future" },
          ],
        },
        {
          label: "Alumni",
          icon: "",
          items: [
            { label: "Stay Connected" },
            { label: "Alumni Testimonial" },
            { label: "Archived Gallery" },
            { label: "Events Reunion" },
          ],
        },
      ],
      [
        {
          label: "Our People",
          icon: "",
          items: [
            { label: "Advisors" },
            { label: "Faculties" },
            { label: "Administration" },
            { label: "Students" },
            { label: "Staffs" },
          ],
        },
        {
          label: "Work With Us",
          icon: "",
          items: [
            {
              label: "Academic Vacancies",
              command: () => {
                navigate("/about/work-with-us/academic-vacancies");
              },
            },
            {
              label: "Administrative Vacancies",
              command: () => {
                navigate("/about/work-with-us/administrative-vacancies");
              },
            },
          ],
        },
      ],
      [
        {
          label: "Our Campus",
          icon: "",
          items: [{ label: "Current Campus" }, { label: "Future Campus" }],
        },
        { label: "Gallery", icon: "", items: [{ label: "Gallery" }] },
      ],
    ],
    command: () => {
      // Callback to run
    },
  },

  //   Contact Us Menu Item
  {
    label: "Contact Us",
    icon: "",
    items: [
      {
        label: "Contact Us",
        command: () => {
          navigate("/contactUs");
        },
        items: [
          {
            label: "Contact Us",
            command: () => {
              navigate("/contactUs");
            },
          },
        ],
      },
    ],
  },

  //   Search Section
  {
    label: "Apply",
    icon: "",
  },

  // Apply Now Section
];
