// import logo from './logo.svg';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../src/assets/styles/styles.scss';
import './App.css';
import HomePage from "../src/pages/home";
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import Curriculum from './pages/curriculum';
import WhoWeAre from './pages/whoWeAre';
import OurPeople from './pages/ourPeople';
import MainLayout from "../src/components/layout/MainLayout";
import WorkWithUs from './pages/workWithUs';
import AcademicLife from './pages/academicLife';
import AcademicVacancies from './pages/academicVacancies';
import AdministrativeVacancies from './pages/administrativeVacancies';
import StudentProjects from './pages/studentProjects';
import Hackathon from './pages/Hackathon';
import Internships from './pages/internships';
import PageNotFound from './pages/pageNotFound';
import Blogs from './pages/blogs';
import InnerBlogs from './pages/innerBlogs';
import WhySitare from './pages/whySitare';
import Projects from './pages/projects';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
        <Route path="/contactUs" element={<MainLayout><ContactUs /></MainLayout>} />
        <Route path="/about" element={<MainLayout><AboutUs /></MainLayout>} />
        <Route path="/who-we-are" element={<MainLayout><WhoWeAre /></MainLayout>} />
        <Route path="/our-people" element={<MainLayout><OurPeople /></MainLayout>} />
        <Route path="/work-with-us" element={<MainLayout><WorkWithUs /></MainLayout>} />
        <Route path="/academic-vacancies" element={<MainLayout><AcademicVacancies /></MainLayout>} />
        <Route path="/administrative-vacancies" element={<MainLayout><AdministrativeVacancies /></MainLayout>} />
        <Route path="/academic-Life" element={<MainLayout><AcademicLife /></MainLayout>} />
        <Route path="/studentProjects" element={<MainLayout><StudentProjects /></MainLayout>} />
        <Route path="/Hackathon" element={<MainLayout><Hackathon /></MainLayout>} />
        <Route path="/internships" element={<MainLayout><Internships /></MainLayout>} />
        <Route path="/curriculum" element={<MainLayout><Curriculum /></MainLayout>} />
        <Route path="/student-projects" element={<MainLayout><StudentProjects /></MainLayout>} />
        <Route path="/hackathon" element={<MainLayout><Hackathon /></MainLayout>} />
        <Route path="/blogs" element={<MainLayout><Blogs /></MainLayout>} />
        <Route path="/blogs/innerBlogs" element={<MainLayout><InnerBlogs /></MainLayout>} />
        <Route path="/whySitare" element={<MainLayout><WhySitare /></MainLayout>} />
        <Route path="/projects" element={<MainLayout><Projects /></MainLayout>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
