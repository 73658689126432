import React from 'react'
import './style.scss';
import HackathonImg from "../../assets/images/hackathon.png";
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import MenuCards from '../../components/sections/MenuCards';
import { ACADEMIC_MENU_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const AcademicLife = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        navigate(`/${route}`);
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl= {HackathonImg} 
                    title= "academic life"
                    desc= "Igniting Curiosity, Inspiring Achievement"
                    themeColor="sh-contentimgsplit--yellow-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5'>
                <MenuCards data={ACADEMIC_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--yellow-theme" />
            </section>
        </>
    )
}

export default AcademicLife;