// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sh-journey {
  padding: 74px 0;
}
@media all and (max-width: 576px) {
  .sh-journey {
    padding: 40px 0;
  }
}
.sh-journey--text {
  font-size: 1.2rem;
  line-height: 1.4;
}
.sh-banner--image {
  width: 100%;
  max-height: 50vh;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/whoWeAre/style.scss"],"names":[],"mappings":"AAII;EACI,eAAA;AAHR;AAIQ;EAFJ;IAGQ,eAAA;EADV;AACF;AAGQ;EACI,iBAAA;EACA,gBAAA;AADZ;AAMQ;EACI,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;AAJZ","sourcesContent":["@import '../../assets/styles/colors';\n// @import '../../assets/styles/styles.scss';\n\n.sh {\n    &-journey {\n        padding: 74px 0;\n        @media all and (max-width: 576px){\n            padding: 40px 0;\n        }\n\n        &--text {\n            font-size: 1.2rem;\n            line-height: 1.4;\n        }\n    }\n\n    &-banner{\n        &--image{\n            width: 100%;\n            max-height: 50vh;\n            border-radius: 10px;\n            object-fit: cover;\n            object-position: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
