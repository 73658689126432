import React from "react";
import "./style.scss";
import bannerImg from "../../assets/images/banner1.jpg";
import BLOG_POSTS from '../../dummyData/BlogPosts.json';
import { Carousel } from "primereact/carousel";
import Next_Icon from '../../assets/images/next.png'
import Prev_Icon from '../../assets/images/prev.png'

const InnerBlogs = () => {


  const responsiveOptions = [
    {
      breakpoint: '3600px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 2,
      numScroll: 1,
    },
    {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
    }
  ];
  const relatedBlogOptions = [
    {
      breakpoint: '3600px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 2,
      numScroll: 1,
    },
    {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
    }
  ];


  const ItemTemplate = (item) => {
    return (
      <div className="sb-blog m-5 p-4">
        <div className='sb-blog--banner'>
          <img src={item?.image} alt='Blog Image' className='sb-blog--banner-img' />
          <p className='sb-blog--banner-category'>{item?.category}</p>
        </div>
        <h3 className='global-subheading sb-blog--title'>{item?.title}</h3>
        <p className='sb-blog--date'>{item?.publishDate}</p>
      </div>
    );
  };

  return (
    <>
      {/* Section: Banner */}
      <section className="sb-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sb-banner--image">
                <img src={bannerImg} alt="banner image" draggable="false" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section:About */}
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <div className="global-aboutwrapper">
              <h1 className="global-subheading global-fw600 mb-4">
                That was the case for Nash and PBIS school-wide expectations. 
              </h1>
              <p className="global-bodytext mb-3">
                We all want our schools to improve. We want schools to be more
                engaging, inclusive, and effective. To that end, we have an
                industry full of talented teachers, administrators, coaches, and
                support staff working to make our schools just a little bit
                better.
              </p>

              <p className="global-bodytext mb-3">
                But better for who? That’s the key question that we want to
                tackle today. If we are going to throw all of our heart and soul
                into making an impact on education…we need to focus on who
                should be the primary beneficiary of that effort: students.
              </p>
              <p className="global-bodytext">
                That’s exactly what Nash Elementary School of Fine and
                Performing Arts in Chicago, Illinois did. They had really
                good systems. They made them great by gathering feedback from
                their students – and acting on that information. Let’s take a
                closer look at how they did it.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Image */}
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="sb-subimage">
              <img src={bannerImg} alt="image" draggable="false" />
            </div>
          </div>
        </div>
      </section>

      {/* Section:About */}
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9 py-5">
          <h1 className="global-subheading global-fw600 mb-4">
                Student Feedback and School-Wide Expectations 
              </h1>
              <p className="global-bodytext mb-3">
                Sometimes feedback isn’t a formal survey. Sometimes feedback
                comes in the form of actions. When it comes to student behavior
                oftentimes you're going to get audible feedback from
                students...but sometimes that feedback is going to come in the
                form of the actual behavior.
              </p>
          </div>
        </div>
      </section>

      {/* Section:About */}
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
          <h1 className="global-subheading global-fw600 mb-4">
                That was the case for Nash and PBIS school-wide expectations.  
              </h1>
              <p className="global-bodytext mb-3">
                PBIS is a proactive approach to behavior management that
                emphasizes teaching and reinforcing positive behaviors to create
                a supportive and inclusive school environment. When they started
                with PBIS they were focused on the acronym “ROAR”:
              </p>
          </div>
        </div>
      </section>

      {/* Section: Blogs */}
      <section className="sb-blogs container">
        <Carousel
          value={BLOG_POSTS}
          numVisible={2}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          className="custom-carousel sb-blogpost--carousel"
          circular={false}
          showIndicators={false}
          autoplayInterval={0}
          itemTemplate={ItemTemplate}
          // prevIcon={<i className="fa-solid fa-angle-left"></i>}
          // nextIcon={<i className="fa-solid fa-angle-right"></i>}
          prevIcon={
            <div className="sb-blogpost--carousel-icon d-flex justify-content-center align-items-center gap-3">
                <img src={Prev_Icon} className="sb-blogpost--carousel-img" />
                <div className="d-flex flex-column">
                  <span className="sb-blogpost--carousel-text">Previous blog:</span>
                  <span className="sb-blogpost--carousel-title">Previous blog title here</span>
                </div>
            </div>
          }
          nextIcon={
              <div className="sb-blogpost--carousel-icon d-flex justify-content-center align-items-center gap-3">
                  <div className="d-flex flex-column">
                    <span className="sb-blogpost--carousel-text">Next blog</span>
                    <span className="sb-blogpost--carousel-title">Next blog title here</span>
                  </div>
                  <img className="sb-blogpost--carousel-img" src={Next_Icon} />
              </div>
          }
          indicatorsContentClassName="ind_wrapper"
        />
      </section>

      {/* Section: Related Blogs */}
      <section className="sb-relatedblog">
        <div className="container">
          <h1 className="global-heading">
            Related Blogs
          </h1>
          <Carousel
            value={BLOG_POSTS}
            numVisible={3}
            numScroll={1}
            responsiveOptions={relatedBlogOptions}
            className="custom-carousel sb-relatedblog--carousel"
            circular={false}
            showIndicators={false}
            autoplayInterval={0}
            itemTemplate={ItemTemplate}
            prevIcon={<i className="fa-solid fa-angle-left"></i>}
            nextIcon={<i className="fa-solid fa-angle-right"></i>}
            indicatorsContentClassName="gdhshds"
          />
        </div>
      </section>
    </>
  );
};

export default InnerBlogs;
