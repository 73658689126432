import React, { useEffect, useState } from 'react'
import './style.scss';
import BlogImg from "../../assets/images/people_Img.png";
import BLOG_POSTS from '../../dummyData/BlogPosts.json';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currPageNumber, setCurrPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);

  useEffect(() => {
    let pages = Math.ceil(BLOG_POSTS.length/itemsPerPage); 
    setTotalPages(pages);
    trimData(1, itemsPerPage);
  }, [BLOG_POSTS]);

  const trimData = (page, itemsPerPage) => {
    const fromInd = (page - 1) * itemsPerPage;
    const toInd = fromInd + itemsPerPage;
    const previousData = JSON.parse(JSON.stringify(BLOG_POSTS));
    const trimData = previousData.slice(fromInd, toInd);
    setCurrentPageData(trimData);
  };

  const handlePageNoClick = (currPage) => {
    trimData(currPage, itemsPerPage)
    setCurrPageNumber(currPage);
  }

  const handlePrevNext = (type, currPage) => {
    if(type == "Prev"){
      if(currPage > 1) {
        trimData(currPage - 1, itemsPerPage)
        setCurrPageNumber(currPage - 1);
      }
    }else{
      if(currPage < totalPages) {
        trimData(currPage + 1, itemsPerPage)
        setCurrPageNumber(currPage + 1);
      }
    }
  }

  const handleBlogClick = () => {
    navigate('/blogs/innerBlogs');
  }


  return (
    <>
      {/* Section: Banner */}
      <section className='bl-banner d-flex justify-content-center align-items-center'>
        <div>
          <h4 className='global-subheading bl-banner--subheading mb-3 text-center'>Blogs</h4>
          <h2 className='global-heading text-center bl-banner--heading'>Lorem ipsum dolor <br /> sit amet</h2>
        </div>
      </section>

      {/* Section: Latest Blogs */}
      <section className='bl-blog'>
        <div className="container">
          <h1 className="global-heading mb-5">latest blog post</h1>
          <div className='bl-blog--wrapper'>
            <div className='bl-blog--image'>
              <img className='sh-contentimgsplit--imageimg' 
                src={BlogImg} alt='Blog Image'
              />
            </div>
            <div className={`bl-blog--content`}>
                <p className='global-bodytext'>Date: 08/10/24</p>
                <p className='global-bodytext text-capitalize'>Author: sitare university</p>
                <h2 className="global-heading my-3 global-fw600">title of the featured blog here</h2>
                <p className='global-bodytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Blogs Filter */}
      <section className='container my-4 d-none d-md-block'>
        <div className='bl-filters'>
          <p className='mb-0 bl-filters--text'>Filter by</p>
          <select className='bl-filters--select'>
            <option>All Category</option>
            <option>Category 1</option>
            <option>Category 2</option>
          </select>
          <select className='bl-filters--select'>
            <option>All Topics</option>
            <option>Topic 1</option>
            <option>Topic 2</option>
          </select>
          <select className='bl-filters--select'>
            <option>This Year</option>
            <option>Year 1</option>
            <option>Year 2</option>
          </select>
          <p className='mb-0 bl-filters--text'>Reset Filters</p>
        </div>
      </section>

      {/* Section: Blogs Cards */}
      <section className='container mt-md-5'>
        <div className='bl-posts'>
          <div className='bl-posts--wrapper'>
            {currentPageData && currentPageData.length > 0 && currentPageData.map((item, index) => (
              <div key={index} onClick={() => handleBlogClick()} className='bl-posts--item'>
                <div className='bl-posts--banner'>
                  <img src={item?.image} alt='Blog Image' className='bl-posts--banner-img' />
                  <p className='bl-posts--banner-category'>{item?.category}</p>
                </div>
                <h3 className='global-subheading bl-posts--title'>{item?.title}</h3>
                <p className='bl-posts--date'>{item?.publishDate}</p>
              </div>
            ))}
          </div>

          <div className='bl-pagination d-flex justify-content-center my-5'>
            <button className='me-3' onClick={() => handlePrevNext("Prev", currPageNumber)}>Previous</button>
            {totalPages && totalPages > 0 && [...Array(totalPages)].map((item, index) => (
              <div className={`bl-pagination--number ${(currPageNumber == (index+1)) && "active"}`} onClick={() => handlePageNoClick(index+1)}>
                <p>{index+1}</p>
              </div>
            ))}
            <button className='ms-3' onClick={() => handlePrevNext("Next", currPageNumber)}>Next</button>
          </div>

        </div>
      </section>

      {/* Section: NewsLetter */}
      <section className='container'>
          <div className='bl-newsletter'>
            <h2 className="global-heading bl-newsletter--title mb-0">Let us take this to your inbox</h2>
            <div className='bl-newsletter--content'>
              <p className='bl-newsletter--desc'>We’ll send you our monthly newsletter which is fully stocked with free resources like articles, videos, podcasts, reward ideas, and anything else we can think of to help you to know us better.</p>
              <div className="bl-newsletter--subscribe">
                <input
                  type="text"
                  className="bl-newsletter--subscribe-field"
                  placeholder="Email"
                />
                <button type="button" className="bl-newsletter--subscribe-button">
                  submit
                </button>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default Blogs;