// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sa-sitare--vision {
  background: #a54253;
  padding: 40px;
}
.sa-sitare--vision p {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
}
@media all and (max-width: 768px) {
  .sa-sitare--vision p {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/whySitare/style.scss","webpack://./src/assets/styles/_colors.scss"],"names":[],"mappings":"AAIQ;EACI,mBCqCF;EDpCE,aAAA;AAHZ;AAIY;EACI,WCER;EDDQ,gBAAA;EACA,eAAA;AAFhB;AAGgB;EAJJ;IAKQ,eAAA;EAAlB;AACF","sourcesContent":["@import '../../assets/styles/colors';\n\n.sa{\n    &-sitare{\n        &--vision{\n            background: $red-700;\n            padding: 40px;\n            p{\n                color: $white;\n                font-weight: 600;\n                font-size: 32px;\n                @media all and (max-width:768px){\n                    font-size: 24px;\n                }\n            }\n        }\n    }\n}","// Blues\n$blue-50:#e6f0fa;\n$blue-100: #B0D1EF;\n$blue-200:#8ABBE8;\n$blue-400:#3389D6;\n$blue-900:#002D56;\n$blue-500: #006BCC;\n$blue-600: #0061ba;\n\n// Global\n$white: #fff;\n$black:#000;\n\n// Oranges\n$orange-50: #FFF3E9;\n$orange-100: #FFDABB;\n$orange-200: #FFC99A;\n$orange-300: #FFB06C;\n$orange-400: #FFA14F;\n$orange-500: #FF8923;\n$orange-600: #e87d20;\n$orange-700: #B56119;\n$orange-800: #8C4B13;\n$orange-900: #6B3A0F;\n\n// Yellow\n$yellow-500:#FDE74C;\n$yellow-300:#FEEF87;\n$yellow-200: #FEF4AD;\n\n// Greens\n$green-50: #ECFAF3;\n$green-300: #82DCAD;\n$green-500: #45CB85;\n$green-800: #267049;\n\n// Red\n$red-200: #F4B4C0;\n$red-300: #F092A3;\n$red-400: #ED7D91;\n$red-500: #E85D75;\n$red-600: #d3556a;\n$red-700: #a54253;\n$red-800: #803340;\n$red-900: #612731;\n\n// Grey\n$text-200: #A3A3A3;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
