export const MENU_CARDS_DATA = [
    {
        id: 1,
        name: "Why Sitare",
        route: "whySitare"
    },
    {
        id: 2,
        name: "Who we are",
        route: "who-we-are"
    },
    {
        id: 3,
        name: "Alumni",
        route: "alumni"
    },
    {
        id: 4,
        name: "Our people",
        route: "our-people"
    },
    {
        id: 5,
        name: "Our campus",
        route: "our-campus"
    },
    {
        id: 6,
        name: "Work with us",
        route: "work-with-us"
    },
    {
        id: 7,
        name: "Gallery",
        route: "gallery"
    }
]


export const ACADEMIC_MENU_DATA = [
    {
        id: 1,
        name: "Curriculum",
        route: ""
    },
    {
        id: 2,
        name: "Student projects",
        route: "studentProjects"
    },
    {
        id: 3,
        name: "Hackathon",
        route: "Hackathon"
    },
    {
        id: 4,
        name: "Internships",
        route: "internships"
    }
]

export const PROJECTS_MENU_DATA = [
    {
        id: 1,
        name: "Student projects",
        route: "curriculum"
    },
    {
        id: 2,
        name: "sitare github",
        route: "student-projects"
    },
    {
        id: 3,
        name: "hugging face",
        route: "hackathon"
    },
    {
        id: 4,
        name: "faculty projects",
        route: "internships"
    }
]
  