import React from 'react';
import "../../assets/styles/styles.scss";
import CrossIcon from '../../assets/images/cross.png'


const VacancyDetails = ({item, vacancyType}) => {
    return (
        <div className="offcanvas offcanvas-end wu-vacancy" tabindex="-1" id={`vacancyDetails_${item?.id}`} aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header justify-content-end">
                <img src={CrossIcon} data-bs-dismiss="offcanvas" className='wu-vacancy--closeicon' />
            </div>
            <div className="offcanvas-body px-5">
                <h1 className="global-heading mb-4">{vacancyType}</h1>
                <h2 className='subheading text-capitalize'>Role: {item?.role}</h2>
                <div className='wu-vacancy--short-info'>
                    <p className='mb-0'>
                        <span className='wu-vacancy--title'>position: </span>
                        <span className='wu-vacancy--description'>{item?.position}</span>
                    </p>
                    <p className='mb-0'>
                        <span className='wu-vacancy--title'>location: </span>
                        <span className='wu-vacancy--description'>{item?.location}</span>
                    </p>
                    <p className='mb-0'>
                        <span className='wu-vacancy--title'>department: </span>
                        <span className='wu-vacancy--description'>{item?.department}</span>
                    </p>
                    <p className='mb-0'>
                        <span className='wu-vacancy--title'>reports to: </span>
                        <span className='wu-vacancy--description'>{item?.reportsTo}</span>
                    </p>
                    <p className='mb-0'>
                        <span className='wu-vacancy--title'>employment type: </span>
                        <span className='wu-vacancy--description'>{item?.employmentType}</span>
                    </p>
                </div>
                <div className='mb-4'>
                    <p className='wu-vacancy--title'>Overview</p>
                    <p className='wu-vacancy--description'>{item?.overview}</p>
                </div>
                <div>
                    <h2 className='subheading text-capitalize mb-4'>key responsibilities</h2>
                    {
                        item.responsibilities && item.responsibilities.length > 0 && item.responsibilities.map((resItem, resInd) => (
                            <div key={resInd}>
                                <h3 className='wu-vacancy--title mb-1'>{resItem?.category}</h3>
                                <ul className='wu-vacancy--list'>
                                    {resItem && resItem.tasks && resItem.tasks.length > 0 && resItem.tasks.map((task, taskInd) => (
                                        <li key={taskInd} className='wu-vacancy--description'>{task}</li>
                                    ))}
                                </ul>
                                
                            </div>
                        ))
                    }
                </div>
                <div>
                    <h2 className='subheading text-capitalize mb-2'>edujcation</h2>
                    <ul className='wu-vacancy--list'>
                        {item.education && item.education.length > 0 && item.education.map((edu, eduInd) => (
                            <li key={eduInd} className='wu-vacancy--description'>{edu}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h2 className='subheading text-capitalize mb-2'>experience</h2>
                    <ul className='wu-vacancy--list'>
                        {item.experience && item.experience.length > 0 && item.experience.map((exp, expInd) => (
                            <li key={expInd} className='wu-vacancy--description'>{exp}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default VacancyDetails