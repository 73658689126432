import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import thumbnail from "../../assets/images/hostel.png";
import BannerImg from "../../assets/images/student_projects.png";
import PedagogyImg from "../../assets/images/pedagogy_img.png";
import "./style.scss";
import "../../assets/styles/styles.scss";
import { Link } from "react-router-dom";

const StudentProjects = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Student Projects"
          desc="Turning Concepts into Reality: Explore, Create, Share"
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>

      {/* Section: Real World Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    From Classroom Concepts to Real-World Impact
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    At Sitare University, the Computer Science program
                    emphasizes the importance of learning beyond the classroom
                    through a variety of student projects. These projects are
                    integral to the curriculum, allowing students to apply
                    theoretical knowledge to practical, real-world problems.
                    Students are encouraged to work on individual and team
                    projects that span a wide range of topics, from developing
                    software applications to creating innovative algorithms for
                    data analysis. Faculty mentors guide students throughout the
                    project lifecycle, providing feedback and support. The
                    culmination of these projects often results in tangible
                    products or services, which students can showcase in their
                    portfolios, demonstrating their skills and ingenuity to
                    potential employers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="sp-pedagogybanner">
        <div className="container">
          <img
            src={PedagogyImg}
            alt="Pedagogy Image"
            className="sp-pedagogybanner--image"
          />
        </div>
      </section>

      {/* Section: Featured Projects By Students */}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div className="">
              <h1 className="global-heading mb-4">
                Featured Projects by Students
              </h1>
              <p className="global-bodytext py-3">
                Our alumni are making waves in their respective fields, and we
                love to celebrate their successes. Here are some inspiring
                stories from our graduates
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Project Box */}
      <section className="container">
        <div className="row mb-5">
          {/* Project 1 */}
          <div className="col-12 col-md-6">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                CityGraph
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link to={"https://github.com/sitareuniversity/classicalai/tree/main/CityGraph"} target="_blank" className="global-orangeBtn">
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Kushal Shah</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">
                  Kirtan Khichi and team
                  </span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                  This is a curated dataset of actual and heuristic (straight line) distances between various cities of India (Bharat), that can be used for various assignments and projects related to BFS, DFS, Greedy Best-First Search and A* Search in a classical AI course. This dataset is the combined efforts of the students of Sitare University.
                  </p>
                </div>
              </div>
            </div>
          </div>

            {/* Project 2 */}
            <div className="col-12 col-md-6">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                Image Editor
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link to={"https://github.com/sitareuniversity/python/tree/main/Image%20Editor"} target="_blank" className="global-orangeBtn">
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Kushal Shah</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">
                  Rajat Malviya and Sandeep Kumar
                  </span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                  ImageEditor is a Flask-based web application that allows users to upload and edit images with various features such as filters, cropping, blur, rotation, face detection, text extraction, and an undo-redo system. Users can also download their edited images.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default StudentProjects;
